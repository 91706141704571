/* eslint-disable @next/next/no-img-element */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
/* eslint-disable prefer-template */
import { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y, Autoplay } from 'swiper';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import Image from 'next/image';

const HeroBanner = ({ data, bannerList }) => {
  const navigationPreviousRef = useRef(null);
  const navigationNextRef = useRef(null);

  return (
    <Swiper
      style={{
        '--swiper-pagination-color': '#FFFFFF',
        '--swiper-pagination-bullet-inactive-color': '#FFFFFF',
        '--swiper-pagination-bullet-inactive-opacity': '0.44',
        '--swiper-pagination-bullet-size': '7px',
        '--swiper-pagination-bullet-horizontal-gap': '3px',
      }}
      modules={[Navigation, Pagination, A11y, Autoplay]}
      initialSlide={0}
      spaceBetween={2}
      pagination={{ clickable: true }}
      slidesPerView={1}
      className="relative overflow-hidden h-full rounded-lg"
      loop={bannerList?.length ? bannerList?.length > 1 : data?.length > 1}
      navigation={{
        prevEl: navigationPreviousRef.current,
        nextEl: navigationNextRef.current,
      }}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      onBeforeInit={(swiper) => {
        swiper.params.navigation.prevEl = navigationPreviousRef.current;
        swiper.params.navigation.nextEl = navigationNextRef.current;
      }}
    >
      {(bannerList?.length ? bannerList : data).map((item, index) => (
        <SwiperSlide key={`hero-banner-${index}`}>
          <a
            href={item.redirectUrl ?? '/'}
            target="_blank"
            className={`w-full h-full main-banner-${index + 1} rounded-lg`}
            rel="noreferrer"
          >
            <div style={{ display: 'flex', flexDirection: 'column' }} className="rounded-lg">
              <Image
                src={item?.bannerImagePath ?? item?.image?.path}
                alt={item?.bannerTitle ?? ''}
                style={{
                  width: '100%',
                  height: 'auto',
                }}
                className="!rounded-lg"
                width={885}
                height={325}
                priority={index === 0}
              />
            </div>
          </a>
        </SwiperSlide>
      ))}
      <div
        ref={navigationPreviousRef}
        className="hidden xl:block absolute top-[50%] transform translate-y-[-50%] left-[12px] z-10 h-[40px] w-[40px] p-2 rounded-full bg-white opacity-70"
      >
        <ChevronLeftIcon width={24} height={24} color="gray" />
      </div>
      <div
        ref={navigationNextRef}
        className="hidden xl:block absolute top-[50%] transform translate-y-[-50%] right-[12px] z-10 h-[40px] w-[40px] p-2 rounded-full bg-white opacity-70"
      >
        <ChevronRightIcon width={24} height={24} color="gray" />
      </div>
    </Swiper>
  );
};

export default HeroBanner;

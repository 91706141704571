import Image from 'next/image';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper';
import PropTypes from 'prop-types';

const RecommendationCarousel = ({ recommendationBanners }) => {
  const data = recommendationBanners?.content || [];

  return (
    <div>
      <h6 className="text-[18px] md:text-xl font-semibold mb-[20px]">
        {recommendationBanners?.title}
      </h6>
      <Swiper
        freeMode
        slidesPerView="1"
        spaceBetween={20}
        breakpoints={{
          320: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        }}
        modules={[FreeMode]}
        className=""
      >
        {data.map((item, index) => {
          const isLastItem = index === data.length - 1;
          return (
            <SwiperSlide key={index} className="rounded-lg">
              <div>
                <a
                  href={item?.redirectUrl ?? ''}
                  // style={{ width: 280, height: 180 }}
                  className="relative h-[120px] lg:h-[180px] rounded-lg"
                >
                  <img
                    src={item.image?.path || '#'}
                    alt={`recommendation-${index}`}
                    className="w-full h-full object-center object-cover rounded-lg"
                  />
                </a>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

RecommendationCarousel.propTypes = {
  recommendationBanners: PropTypes.any,
};

export default RecommendationCarousel;

import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y } from 'swiper';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import Search from './Search';

const HeroBanner = ({ data }) => (
  <div className="relative rounded-[20px] md:h-[442px]">
    {data.map((item, index) => (
      // <a href={item.redirectUrl ?? '/'} className="hidden md:inline w-full h-full">
      //   <img src={item.image.path} alt="" className="h-full w-full object-left-top object-cover" />
      // </a>
      <img
        key={index}
        src={item.image.path}
        alt=""
        className="hidden md:block h-full w-full rounded-[20px] object-left-top object-cover"
      />
    ))}
    <Search />
  </div>
);

export default HeroBanner;

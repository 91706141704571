import Homepage from 'containers/Homepage';
import HomepageCallpro from 'containers/HomepageCallpro';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { CONFIG_SITE } from 'constants/index';
import { WHITELABEL_KEYS } from 'global/constants/whitelabel-keys';
import HomepageIDFood from 'containers/HomepageIDFood';
import Head from 'next/head';

const HomePage = (props) => {
  const { firstHeroBannerUrl = '' } = props;

  const renderContent = () => {
    if (CONFIG_SITE?.WHITELABEL_KEY === WHITELABEL_KEYS.callpro) {
      return <HomepageCallpro {...props} />;
    }

    if (CONFIG_SITE?.WHITELABEL_KEY === WHITELABEL_KEYS.idfood) {
      return <HomepageIDFood {...props} />;
    }

    return <Homepage {...props} />;
  };

  return (
    <>
      {CONFIG_SITE?.BANNER_MANAGEMENT && (
        <Head>
          {firstHeroBannerUrl && (
            <link
              rel="preload"
              fetchpriority="high"
              as="image"
              href={firstHeroBannerUrl}
              type="image/webp"
            />
          )}
        </Head>
      )}

      {renderContent()}
    </>
  );
};

export const getServerSideProps = async ({ locale }) => {
  let firstHeroBannerUrl = '';
  let bannerList = null;

  if (CONFIG_SITE?.BANNER_MANAGEMENT) {
    const url = `${process.env.BASE_API_URL}/api/v2/contentful/banner/list`;
    const response = await fetch(url);
    const data = await response.json();
    bannerList = data?.data;
    firstHeroBannerUrl = data?.data?.[0]?.bannerImagePath;
  }

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'index-page'])),
      pageTemplateCode: 'homepage',
      bannerList,
      firstHeroBannerUrl,
    },
  };
};

export default HomePage;

/* eslint-disable no-param-reassign */
import { useMemo, useRef, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, A11y } from 'swiper';
import { LocationMarkerIcon } from '@heroicons/react/solid';
import { uniqueId, startCase, toLower } from 'lodash';
import { parseCookies } from 'nookies';
import Link from 'next/link';
import { CONFIG_SITE } from 'constants/index';
import { useAuth } from 'utils/context/auth';
import { currencyFormatter } from 'global/utils/currency.utils';
import { isValidBuyingType } from 'global/utils/global-config.utils';
import { BUYING_OPTIONS } from 'global/constants/buying-options';

const HighlightProductCarousel = ({ productSection }) => {
  const banners = productSection?.content?.banner ? [productSection?.content?.banner] : [];
  const data = productSection?.content?.products || [];
  const title = productSection?.title || '';
  const seeMoreUrl = `/search?cateIds=${productSection?.categoryId || ''}`;

  const navigationPreviousRef = useRef(null);
  const navigationNextRef = useRef(null);

  const cookies = parseCookies();
  const { user } = useAuth();
  const { isLogedIn } = user.auth;
  const [isLogin, setIsLogin] = useState(false);

  const isBuyingTypeRent = isValidBuyingType(CONFIG_SITE, BUYING_OPTIONS.rent);

  const addressProduct = (product) => {
    const { productTypePropertyCity, productTypePropertyDistrict, sellerStore } = product || {};
    const { cityName: storeCityName, districtName: storeDistrictName } =
      sellerStore?.storeAddress || {};

    const propertyDistrictName = productTypePropertyDistrict || '';
    const propertyCityName = productTypePropertyCity || '';

    const districtName = isBuyingTypeRent ? propertyDistrictName : storeDistrictName;
    const cityName = isBuyingTypeRent ? propertyCityName : storeCityName;

    // const labels = [
    //   upperFirst(String(districtName).toLowerCase()),
    //   upperFirst(String(cityName).toLowerCase()),
    // ];

    const labels = [String(startCase(toLower(districtName))), String(startCase(toLower(cityName)))];
    return labels.filter((item) => !!item).join(', ');
  };

  const loadPriceValidate = (product) => {
    if (CONFIG_SITE.HIDE_PRODUCT_PRICE_IF_UNAUTHENTICATED) {
      if (isLogin) {
        return `Rp${currencyFormatter(product.finalPrice)}`;
      }
      return 'Login For Price';
    }
    return `Rp${currencyFormatter(product.finalPrice)}`;
  };

  const mappingCategoryTextColor = (category) => {
    switch (category) {
      case 'Space':
        return 'text-[#00BB13]';
      case 'Ruang Kantor':
        return 'text-[#3083FE]';
      default:
        return 'text-black';
    }
  };

  const mappingRentUnit = (unit) => {
    switch (unit) {
      case 'DAY':
        return 'Hari';
      case 'MONTH':
        return 'Bulan';
      default:
        return 'Tahun';
    }
  };

  useEffect(() => {
    if (cookies.isLogedIn) {
      setIsLogin(true);
    }
  }, [isLogedIn]);

  const slider = useMemo(
    () => (
      <Swiper
        modules={[Navigation, A11y]}
        slidesPerView="1"
        spaceBetween={20}
        breakpoints={{
          320: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        }}
        className="relative overflow-x-visible"
        navigation={{
          prevEl: navigationPreviousRef.current,
          nextEl: navigationNextRef.current,
        }}
        onBeforeInit={(swiper) => {
          setTimeout(() => {
            swiper.params.navigation.prevEl = navigationPreviousRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;

            // Re-init navigation
            swiper.navigation.destroy();
            swiper.navigation.init();
            swiper.navigation.update();
          }, 200);
        }}
        onSwiper={(swiper) => {
          setTimeout(() => {
            swiper.params.navigation.prevEl = navigationPreviousRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;

            // Re-init navigation
            swiper.navigation.destroy();
            swiper.navigation.init();
            swiper.navigation.update();
          }, 200);
        }}
      >
        {/* <SwiperSlide style={{ width: 120 }} className="hidden md:block "></SwiperSlide> */}
        {data.map((product, index) => {
          const isLastItem = index === data.length - 1;
          const isPropertyRented = product?.stock === 0;

          return (
            <SwiperSlide key={uniqueId()} className="rounded-lg">
              <Link href={`/product/${product?.productId}`} key={index}>
                <a className="flex h-[354px] w-full overflow-hidden z-10 bg-white cursor-pointer rounded-lg">
                  <figure className="flex flex-col justify-between w-full">
                    <div className="flex min-h-[184px] w-full">
                      {isPropertyRented && (
                        <div className="absolute z-10 bg-black bg-opacity-60 px-2 py-1 m-2 rounded-[16px]">
                          <p className="text-xs font-bold text-white">Tersewa</p>
                        </div>
                      )}
                      <img
                        // width="100%"
                        // height={160}
                        src={product?.image?.path}
                        alt={product?.image?.seo?.alt}
                        className={`h-full w-full object-cover object-center ${
                          isPropertyRented ? 'opacity-50' : ''
                        }`}
                        // className="object-center object-cover w-full h-full"
                      />
                    </div>
                    <div className="flex-1">
                      <figcaption
                        className={`p-4 pl-3.5 pb-[21px] lg:pr-[35px] w-full h-[205px] ${
                          isPropertyRented ? 'opacity-60' : ''
                        }`}
                      >
                        <div className="flex gap-[6px]">
                          <div className="h-[11.75px] w-[12.5px]">
                            <img
                              src="/assets/illustrations/homepage-category-icon.png"
                              className="w-full h-full"
                              alt="homepage-category-icon"
                            />
                          </div>
                          <p
                            className={`text-[11px] leading-[15px] tracking-[0.2px] mb-[5px] font-semibold ${mappingCategoryTextColor(
                              product?.categoryName,
                            )}`}
                          >
                            {product?.categoryName}
                          </p>
                        </div>
                        <p className="mb-[5px] min-h-[40px] text-sm lg:text-lg tracking-[0.2px] font-semibold text-[#222222]  line-clamp-2 ">
                          {product?.title}
                        </p>

                        <div className="flex items-center space-x-[5px] mb-[5px]">
                          <div className="h-[10px] w-[13.33px]">
                            <img
                              src="/assets/illustrations/homepage-width-icon.png"
                              className="w-full h-full"
                              alt="homepage-width-icon"
                            />
                          </div>
                          <p className="text-[#888888] break-words text-ellipsis w-full line-clamp-2 text-[11px]  tracking-[0.2px]">
                            {product?.propertyArea}m²
                          </p>
                        </div>

                        <p className="mb-[5px] font-semibold text-theme-primary-main text-base tracking-[0.2px] line-clamp-1 mb-[13px]">
                          {loadPriceValidate(product)}{' '}
                          <span className="text-xs">/ {mappingRentUnit(product?.rentUnit)}</span>
                        </p>

                        <div className="flex space-x-[5px] mb-[5px]">
                          <LocationMarkerIcon width={14} height={14} className="text-[#666666]" />
                          <p className="text-[#888888] break-words text-ellipsis w-full line-clamp-2 text-[11px] leading-[15px] tracking-[0.2px]">
                            {addressProduct(product)}
                          </p>
                        </div>
                      </figcaption>
                    </div>
                  </figure>
                </a>
              </Link>
            </SwiperSlide>
          );
        })}
      </Swiper>
    ),
    [data],
  );

  return (
    <div>
      <div className="flex mt-3 justify-between items-center mb-[10px]">
        <h6 className="text-[18px] md:text-xl font-semibold">{title}</h6>
        <Link href={`${seeMoreUrl || '/'}`}>
          <a className="text-sm lg:text-base text-theme-primary-main hover:underline font-semibold">
            Lihat Lainnya &gt;
          </a>
        </Link>
      </div>
      <div className="pl-0 relative">{slider}</div>
    </div>
  );
};

HighlightProductCarousel.propTypes = {};

export default HighlightProductCarousel;

/* eslint-disable @next/next/no-img-element */
/* eslint-disable import/no-unresolved */
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper';
import PropTypes from 'prop-types';
import Image from 'next/image';

const RecommendationCarousel = ({ recommendationBanners }) => {
  const data = recommendationBanners?.content || [];

  return (
    <div>
      <h6 className="text-[18px] md:text-xl font-semibold mb-3">{recommendationBanners?.title}</h6>
      <Swiper
        freeMode
        slidesPerView="1"
        spaceBetween={20}
        breakpoints={{
          320: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        }}
        modules={[FreeMode]}
      >
        {data.map((item, index) => (
          <SwiperSlide key={index}>
            <div>
              <a href={item?.redirectUrl ?? ''} className="relative">
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Image
                    src={item.image?.path || '#'}
                    alt={`recommendation-${index}`}
                    width={280}
                    height={180}
                    style={{
                      width: '100%',
                      height: 'auto',
                    }}
                  />
                </div>
              </a>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

RecommendationCarousel.propTypes = {
  recommendationBanners: PropTypes.any,
};

export default RecommendationCarousel;
